body {
    padding: 20px 80px;
    font-family: 'Nunito', sans-serif;
}

a {
    text-decoration: none;
    text-transform: uppercase;
    color: black;
}

a:hover {
    text-decoration: underline;
}